import { Container } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

const ThankYouImage = () => {
  const { thankYou } = useStaticQuery(graphql`
    {
      thankYou: file(relativePath: { eq: "thank-you.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1024, layout: CONSTRAINED)
        }
      }
    }
  `);

  const img = thankYou.childImageSharp.gatsbyImageData;

  return (
    <Container
      style={{
        marginTop: 120,
        padding: '32px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GatsbyImage image={img} alt="Thank You" />
    </Container>
  );
};

export default ThankYouImage;
