import * as React from 'react';
import ThankYouImage from '../components/ThankYou/ThankYouImage';
import Layout from '../layouts/Layout';

const ThankYou = () => {
  return (
    <Layout title="Thank You">
      <ThankYouImage />
    </Layout>
  );
};

export default ThankYou;
